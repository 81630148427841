import moment from 'moment';
import React, { useState, useEffect, useMemo } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getAllActivitiesSchedules } from 'src/Stores/schedule/actions';
import {
  getDaysOfWeek,
  getScheduleWeekRange,
  startAtHoursFromInterval,
  timeHour,
} from 'src/Utils/Schedule/utils';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import '../styles.scss';
import { connect } from 'react-redux';
import { IRootState } from 'src/Stores';
import { CommonLoading } from 'react-loadingg';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { ActivitySchedule } from 'src/Models/ActivitySchedule';
import {
  defaultMultiScheduleModal,
  defaultOpenScheduleDetails,
} from 'src/Utils/Schedule/constants';
import DisplayCurrentTime from './components/DisplayCurrentTime';
import { useHistory } from 'react-router-dom';
import { Agenda, DaysOfWeek, DaysOfWeekUtils } from 'src/Models/Agenda';
import {
  getAgendaById,
  deleteAgenda,
  resetAgenda,
} from 'src/Stores/agenda/actions';
import _, { isEmpty } from 'lodash';
import ScheduleGridCell from './components/ScheduleGridCell';
import BaseModalSingleSchedule from '../../BaseModalSingleSchedule';
import { ReactComponent as Pencil } from '../../../../Images/Icons/pencil.svg';
import { DeleteOutline, Today, HelpOutline } from '@material-ui/icons';
import { Popover, Tooltip } from '@material-ui/core';
import i18n from 'src/Config/I18n';
import VideoTutorial, { VideoType } from '../../VideoTutorial';

type MultiScheduleType = typeof defaultMultiScheduleModal;
type ScheduleDetailType = typeof defaultOpenScheduleDetails;

export interface ScheduleTableProps
  extends WithTranslation,
    DispatchProps,
    StateProps {
  setOpenAvailableSchedule: (AvailableSchedule) => void;
  setOpenMultiSchedulesModal: (MultiScheduleType) => void;
  setOpenScheduleDetailsModal: (ScheduleDetailType) => void;
  agendas: Agenda[];
}

const ScheduleTable: React.FC<ScheduleTableProps> = ({
  t,
  schedule,
  agendas,
  deleteAgenda,
  deleteAgendaStatus,
  updateAgendaStatus,
  resetAgenda,
  getAgendaById,
  setOpenAvailableSchedule,
  getAllActivitiesSchedules,
  setOpenMultiSchedulesModal,
  setOpenScheduleDetailsModal,
}) => {
  const history = useHistory();
  const [calendarConfig, setCalendarConfig] = useState({
    initialDate: moment().startOf('isoWeek'),
  });
  const [schedulesFilter, setSchedulesFilter] = useState(null);
  const [schedules, setSchedules] = useState<ActivitySchedule[]>([]);
  const [openSingleScheduleModal, setOpenSingleScheduleModal] = useState(false);
  const [agendaSelected, setAgendaSelected] = useState<Agenda | undefined>();
  const [selectedAgendaId, setSelectedAgendaId] = useState<number>(0);
  const [scheduleTableInterval, setScheduleTableInterval] = useState<
    5 | 10 | 15 | 30
  >(30);

  const [anchorEl, setAnchorElement] = useState(null);
  const [popOverOpen, setPopOverOpen] = useState(false);
  const currentTimeRef = React.useRef(null);

  useEffect(() => {
    if (currentTimeRef.current) {
      currentTimeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [currentTimeRef.current]);

  useEffect(() => {
    if (schedules.length === 0) return;
    let interval: 5 | 10 | 15 | 30 = 30;
    for (let i = 0; i < schedules.length; i++) {
      const minutes = moment(schedules[i].date).minutes();
      if (minutes === 5 || minutes === 25 || minutes === 35 || minutes === 55) {
        interval = 5;
        break;
      } else if (minutes === 10) {
        interval = 10;
      } else if (interval !== 10 && (minutes === 15 || minutes === 45)) {
        interval = 15;
      } else if (interval !== 10 && interval !== 15 && minutes === 30) {
        interval = 30;
      }
    }
    setScheduleTableInterval(interval);
  }, [schedules]);

  useEffect(() => {
    if (agendas && schedule.allActivitiesSchedules?.length == 0) {
      const generateds = generatePresets(agendas);
      setSchedules(generateds);
    } else {
      setSchedules(schedule?.allActivitiesSchedules);
    }
  }, [schedule, setSchedules, agendas]);

  useEffect(() => {
    const { firstDay, lastDay } = getScheduleWeekRange(
      calendarConfig?.initialDate,
    );

    const fromDate = moment(firstDay?.date).format('YYYY-MM-DD');
    const toDate = moment(lastDay?.date).add(1, 'days').format('YYYY-MM-DD');

    localStorage.setItem('schedulesFilter', schedulesFilter);
    localStorage.setItem('fromDate', fromDate);
    localStorage.setItem('toDate', toDate);

    getAllActivitiesSchedules(schedulesFilter, fromDate, toDate);
  }, [schedulesFilter, calendarConfig, getAllActivitiesSchedules]);

  useEffect(() => {
    const { firstDay, lastDay } = getScheduleWeekRange(
      calendarConfig?.initialDate,
    );

    const fromDate = moment(firstDay?.date).format('YYYY-MM-DD');
    const toDate = moment(lastDay?.date).add(1, 'days').format('YYYY-MM-DD');

    getAllActivitiesSchedules(schedulesFilter, fromDate, toDate);
  }, [getAllActivitiesSchedules, getScheduleWeekRange]);

  useEffect(() => {
    if (
      deleteAgendaStatus == HttpRequestStatus.SUCCESS ||
      deleteAgendaStatus == HttpRequestStatus.ERROR
    ) {
      resetAgenda();
      setAgendaSelected(undefined);
      setSelectedAgendaId(0);
    }
  }, [deleteAgendaStatus]);

  const generatePresets = (agendas: Agenda[]) => {
    const fromDate = localStorage.getItem('fromDate');
    const toDate = moment(localStorage.getItem('toDate')).add(1, 'd');
    let generated = [];

    agendas?.forEach((agenda) => {
      let currDate = moment(fromDate);

      while (currDate.isBefore(toDate)) {
        const dayOfWeek = DaysOfWeekUtils.fromDay(currDate.day());
        const presets = agenda?.presetAgendaActivitySchedules
          ?.filter((it) => it.dayOfWeek == dayOfWeek)
          .map((it) => {
            const startAt = moment(it.startAt, 'HH:mm:SS');
            const date = currDate.set({
              hour: startAt.hour(),
              minute: startAt.minute(),
            });
            return {
              id: it.id,
              dayOfWeek,
              date: date?.toISOString(),
              zoneId: it.zoneId,
              agenda,
              status: 'PRESET',
              customerActivities: [],
              activity: {},
            };
          });
        currDate = currDate.add(1, 'day');
        generated = generated.concat(presets ?? []);
      }
    });

    return generated;
  };

  const DisplayDays = () => {
    const daysOfWeek = getDaysOfWeek(calendarConfig?.initialDate);

    return (
      <div className="days2">
        <div className="filler" />
        <div className="filler" />
        {daysOfWeek?.map((weekDay, index) => (
          <div id="day" className="day" key={weekDay + index}>
            <span>{weekDay?.title}</span>
            <br />
            <span className="date">{weekDay?.dayNumber}</span>
          </div>
        ))}
      </div>
    );
  };

  const DisplayHours = () => {
    return (
      <>
        {startAtHoursFromInterval(scheduleTableInterval)?.map(
          (hourObj, index) => {
            const gridIndex = index + 1;

            return (
              <div
                className="time"
                key={hourObj}
                style={{ gridRow: gridIndex }}>
                {hourObj}
              </div>
            );
          },
        )}
      </>
    );
  };

  const DisplayLinesAndRows = () => {
    return (
      <>
        {/* <div className="filler-col" /> */}
        <DisplayCurrentTime
          initialDate={calendarConfig?.initialDate}
          interval={scheduleTableInterval}
          currentTimeRef={currentTimeRef}
        />
        {Array.from(Array(7).keys()).map((it, index) => (
          <div
            className="col"
            style={{
              gridColumn: index + 3,
              gridRow: `1 / span ${(24 * 60) / scheduleTableInterval}`,
            }}
            key={it}
          />
        ))}
        {startAtHoursFromInterval(scheduleTableInterval)?.map((it, index) => (
          <div className="row" style={{ gridRow: index + 1 }} key={it} />
        ))}
      </>
    );
  };

  const filterSchedules = (activityType = '') => {
    if (activityType === schedulesFilter) return setSchedulesFilter('');
    setSchedulesFilter(activityType);
  };

  const handleOpenSchedulesOption = ({ schedulesObj, key }) => {
    const currentSchedule = schedulesObj[key] || [];

    if (currentSchedule?.some((it) => it.status == 'PRESET')) {
      const item = currentSchedule?.filter((it) => it.status == 'PRESET');
      setOpenAvailableSchedule({ open: true, currentSchedule: item?.[0] });
    } else if (currentSchedule.length > 0) {
      setOpenMultiSchedulesModal({
        open: true,
        scheduleBoxes: currentSchedule,
      });
    }
  };

  const handleOpenDetails = (schedule) =>
    setOpenScheduleDetailsModal({ open: true, schedule });

  const handlePreviousWeek = () => {
    const { initialDate } = calendarConfig;

    const previousWeek = moment(initialDate).subtract(7, 'days');

    setCalendarConfig({ ...calendarConfig, initialDate: previousWeek });
  };

  const handleNextWeek = () => {
    const { initialDate } = calendarConfig;

    const nextWeek = moment(initialDate).add(7, 'days');

    setCalendarConfig({ ...calendarConfig, initialDate: nextWeek });
  };

  const handleOpenAvailableSchedule = (currentSchedule) => {
    // todo:
    // if (moment().subtract(8, 'hours').isBefore(moment(currentSchedule?.date))) {
    setOpenAvailableSchedule({ open: true, currentSchedule });
    // }
  };

  const buildedSchedules = useMemo(() => {
    if (schedules.length === 0) return {};

    const { lastDay, firstDay } = getScheduleWeekRange(
      calendarConfig?.initialDate,
    );

    const fromDate = moment(firstDay?.date);
    const toDate = moment(lastDay?.date);

    const allSchedulesThatBelongsToTheCurrentWeekDisplaying = schedules.filter(
      (schedule) => {
        return (
          moment(schedule?.date).dayOfYear() >= fromDate.dayOfYear() &&
          moment(schedule?.date).dayOfYear() <= toDate.dayOfYear()
        );
      },
    );

    allSchedulesThatBelongsToTheCurrentWeekDisplaying.sort(
      (a, b) => a?.customerActivities?.length - b?.customerActivities?.length,
    );

    let schedulesObj = {};

    const builded = allSchedulesThatBelongsToTheCurrentWeekDisplaying?.map(
      (schedule, index) => {
        const { date = '', activity = {} } = schedule || {};
        const { duration = 0 } = activity || {};

        let marginLeft = 6;

        const scheduledHour = moment(date).hours();
        const day = Number(moment(date).format('D'));
        const scheduledMinutes = moment(date).minutes();
        const timeInMinutes = scheduledHour * 60 + scheduledMinutes;
        const key = moment(date).format('E_DDD_HH_mm');
        const scheduleRow = Math.floor(timeInMinutes / scheduleTableInterval);
        const agendaColor = schedule?.agenda?.color;
        const agenda = schedule?.agenda;

        const column =
          3 +
          Number(moment(date).format('DDD')) -
          Number(moment(calendarConfig?.initialDate).format('DDD'));

        const sameDaySameHour =
          schedules.filter(
            (it) => it.date == schedule.date && it.id != schedule.id,
          ) || [];
        const sameArrayLength = sameDaySameHour.length;

        if (sameArrayLength > 0) {
          const count = schedules
            .slice(0, index)
            .filter((it) => it.date == schedule.date).length;
          marginLeft += 6 * count;
        }

        const scheduleEnd = moment(
          scheduledHour + ':' + scheduledMinutes,
          'HH:mm',
        )
          .add(duration, 'minutes')
          .format('HH:mm');

        let finalMinutes = '00';

        if (scheduledMinutes !== 0) {
          finalMinutes = '0' + scheduledMinutes.toString();
          finalMinutes = finalMinutes.slice(-2);
        }

        const newScheduleBody = {
          key,
          day,
          duration,
          column,
          scheduleEnd,
          marginLeft,
          ...schedule,
          finalMinutes,
          scheduledHour,
          scheduledMinutes,
          scheduleRow: scheduleRow + 1,
          multiSchedules: sameArrayLength > 0,
          agendaColor,
          agenda,
        };

        schedulesObj = {
          ...schedulesObj,
          [key]: [...(schedulesObj[key] || []), newScheduleBody],
        };

        return newScheduleBody;
      },
    );

    return { builded, schedulesObj };
  }, [schedules, scheduleTableInterval]);

  const memoizedDisplayEvents = useMemo(() => {
    const { builded = [], schedulesObj = {} } = buildedSchedules || {};
    const now = moment().subtract(90, 'minutes');
    return builded?.map((schedule) => {
      const { customerActivities = [] } = schedule || {};

      let onClickFunc = () => {
        // todo:
        // if (moment().subtract(7, 'days').isBefore(moment(schedule?.date))) {
        // }
        handleOpenDetails(schedule);
      };

      if (schedule?.multiSchedules) {
        onClickFunc = () =>
          handleOpenSchedulesOption({
            key: schedule?.key,
            schedulesObj,
          });
      }

      const { status: scheduleStatus } = schedule;

      if (customerActivities.length === 0) {
        if (scheduleStatus === 'PRESET') {
          const tooltip =
            (agendaSelected ?? schedule?.agenda)?.activities?.length > 0
              ? i18n.t('home.schedules.preset.withActivity')
              : i18n.t('home.schedules.preset.withoutActivity');
          return (
            <ScheduleGridCell
              key={schedule.id + scheduleStatus}
              schedule={schedule}
              scheduled={false}
              title={agendaSelected?.name ?? schedule?.agenda?.name}
              tooltip={tooltip}
              onClick={() =>
                handleOpenSchedulesOption({
                  key: schedule?.key,
                  schedulesObj,
                })
              }
              color={agendaSelected?.color ?? schedule?.agenda?.color}
            />
          );
          return;
        }
        if (scheduleStatus !== 'BLOCK') {
          return (
            <ScheduleGridCell
              key={schedule.id + scheduleStatus}
              schedule={schedule}
              title={
                moment(schedule.date).isBefore(now)
                  ? 'Indisponível'
                  : schedule?.agenda?.name ?? 'Disponível'
              }
              onClick={
                !schedule.multiSchedules
                  ? () => handleOpenAvailableSchedule(schedule)
                  : onClickFunc
              }
              color={
                moment(schedule.date).isBefore(now)
                  ? '#8D979E'
                  : agendaSelected?.color ??
                    schedule?.agenda?.color ??
                    '#25B379'
              }
            />
          );
        }
        return (
          <ScheduleGridCell
            key={schedule.id + scheduleStatus}
            schedule={schedule}
            title={'Bloqueado'}
            onClick={() => handleOpenAvailableSchedule(schedule)}
            color="#df6518"
          />
        );
      }
      return (
        <ScheduleGridCell
          key={schedule.id + scheduleStatus}
          schedule={schedule}
          onClick={onClickFunc}
          scheduled
        />
      );
    });
  }, [buildedSchedules]);

  if (
    schedule?.scheduleStatus === HttpRequestStatus.ONGOING ||
    deleteAgendaStatus === HttpRequestStatus.ONGOING ||
    updateAgendaStatus === HttpRequestStatus.ONGOING
  ) {
    return (
      <div style={{ height: '500px', position: 'relative' }}>
        <CommonLoading />
      </div>
    );
  }

  const getSelectedFilter = (option = '') => {
    if (schedulesFilter === option) return 'selectedFilter';
  };

  const handleOnSelectAgenda = (event) => {
    const value = event?.target?.value;

    if (value === 'Todas as agendas') {
      if (schedule.allActivitiesSchedules?.length == 0) {
        const generateds = generatePresets(agendas);
        setSchedules(generateds);
      } else {
        setSchedules(schedule.allActivitiesSchedules);
      }
      setAgendaSelected(undefined);
      return;
    }
    if (value != null) {
      const filtered = schedule?.allActivitiesSchedules.filter(
        (it) => it?.agenda.id == value,
      );
      const agenda = agendas.find((it) => it.id == value);
      if (isEmpty(agenda?.activities) && isEmpty(filtered)) {
        const generateds = generatePresets([agenda]);
        setSchedules(generateds);
        setAgendaSelected(agenda);
        return;
      }
      setSchedules(filtered);
      setAgendaSelected(agenda);
    }
  };

  const handleCloseSingleScheduleModal = () => {
    setOpenSingleScheduleModal(false);
  };

  return (
    <>
      <div className="schedules-header">
        <Popover
          id="mouse-over-popover"
          style={{
            maxWidth: '94vw',
          }}
          open={popOverOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={() => setPopOverOpen(false)}
          disableRestoreFocus>
          <VideoTutorial
            alertTitle={t('global.vTutorial.editScheduleAlert')}
            title={t('global.vTutorial.editScheduleTitle')}
            type={VideoType.EDIT_SCHEDULE_VIDEO}
          />
        </Popover>
        {/* <div className="filterHeader">
          <div
            className={`filterButton ${getSelectedFilter('ONLINE')}`}
            onClick={() => filterSchedules('ONLINE')}>
            Online
          </div>
          <div
            className={`filterButton ${getSelectedFilter('IN_COMPANY')}`}
            onClick={() => filterSchedules('IN_COMPANY')}>
            Empresa
          </div>
          <div
            className={`filterButton ${getSelectedFilter('IN_PLACE')}`}
            onClick={() => filterSchedules('IN_PLACE')}>
            Local
          </div>
        </div> */}
        <div className="items-header">
          <div className="filterAgendas">
            <select
              style={{ color: agendaSelected?.color ?? '#25b379' }}
              onChange={handleOnSelectAgenda}
              defaultValue={'Todas as agendas'}>
              <option value={'Todas as agendas'}>Todas as agendas</option>
              {agendas?.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item?.name}
                  </option>
                );
              })}
            </select>
            {agendaSelected?.id != null ? (
              <div className="rowActions">
                <button
                  className="PartnerDefaultButton"
                  onClick={() =>
                    history.push(`/new-schedule/${agendaSelected?.id}`)
                  }>
                  <Pencil />
                  Editar
                </button>
                <button
                  className="PartnerDefaultButton deleteButton"
                  onClick={() => {
                    deleteAgenda(agendaSelected?.id);
                  }}>
                  <DeleteOutline />
                  Excluir
                </button>
              </div>
            ) : (
              <Tooltip title="Selecione uma agenda ">
                <div className="rowActions">
                  <button className="addNewScheduleButtonDisabled">
                    <Pencil />
                    Editar
                  </button>
                  <button className="addNewScheduleButtonDisabled">
                    <DeleteOutline />
                    Excluir
                  </button>
                </div>
              </Tooltip>
            )}
          </div>
          <div className="tutorial-help">
            <HelpOutline
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                setAnchorElement(e.currentTarget);
                setPopOverOpen(true);
              }}
            />
          </div>
          <div>
            <button
              className="addNewScheduleButton"
              onClick={() => history.push('/new-schedule')}>
              + Criar nova agenda
            </button>
            <BaseModalSingleSchedule />
          </div>
        </div>
      </div>
      <div className="headerBox">
        <div className="scheduleArrows" onClick={handlePreviousWeek}>
          <ArrowBackIosIcon />
        </div>
        <div className="date">
          {moment(calendarConfig?.initialDate)
            .format('MMMM YYYY')
            .toUpperCase()}
        </div>

        <div className="scheduleArrows" onClick={handleNextWeek}>
          <ArrowForwardIosIcon />
        </div>
      </div>
      <DisplayDays />
      <div className="ScheduleComponent">
        <div className="calendar">
          <div className="title" />
          <div></div>
          <div className="content">
            <DisplayHours />
            <DisplayLinesAndRows />
            {memoizedDisplayEvents}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { schedule = {}, agenda } = state || {};

  return {
    schedule,
    deleteAgendaStatus: agenda.deleteStatus,
    updateAgendaStatus: agenda.editStatus,
  };
};

const mapDispatchToProps = {
  getAllActivitiesSchedules,
  getAgendaById,
  deleteAgenda,
  resetAgenda,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ScheduleTable),
) as React.ComponentType<any>;
